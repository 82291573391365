<template>
  <div class="vms-deliveries__form vd-margin-top-medium">
    <div class="row">
      <div class="col-12">
        <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">Video Name</div>
        <div class="vd-margin-bottom-extra-small">
          {{ videoNameValue }}
        </div>
      </div>

      <div class="col-6">
        <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">Revisions</div>
        <div class="vd-margin-bottom-extra-small">
          {{ revisions }}
        </div>
      </div>

      <div class="col-6">
        <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
          Production Date
        </div>
        <div class="vd-margin-bottom-extra-small">
          {{ productionDate }}
        </div>
      </div>

      <div class="col-6">
        <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">Duration</div>
        <div class="vd-margin-bottom-extra-small">
          {{ duration }}
        </div>
      </div>

      <div class="col-12">
        <div class="vd-margin-bottom-large">
          <product-element-extras
            id="deliveriesAdditionalExtrasSelect"
            :available-extras="videoProductElements"
            :product-elements-list="videoProductElements"
            :is-edit-type="isEditType"
            :selected-product-elements="selectedProductElements"
            :has-extras="hasExtras"
            :can-update-extras="false"
            product-elements-select-label="Production Elements"
          ></product-element-extras>
        </div>
      </div>

      <div class="col-12">
        <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
          General Comments
        </div>
        <div class="vd-margin-bottom-extra-small">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="generalComments"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductElementExtras from '@views/project/components/forms/components/product-element-extras'

export default {
  components: {
    ProductElementExtras,
  },
  props: {
    videoNameValue: {
      type: String,
      required: false,
      default: '',
    },

    revisions: {
      type: [Number, String],
      required: false,
      default: '',
    },

    stateName: {
      type: String,
      required: false,
      default: '',
    },

    productionDate: {
      type: String,
      required: false,
      default: '',
    },

    duration: {
      type: String,
      required: false,
      default: '',
    },

    dueDate: {
      type: String,
      required: false,
      default: '',
    },

    videoProductElements: {
      type: Array,
      required: false,
      default: () => [],
    },

    isEditType: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectedProductElements: {
      type: Array,
      required: false,
      default: () => [],
    },

    hasExtras: {
      type: Boolean,
      required: false,
      default: false,
    },

    canUpdateField: {
      type: Boolean,
      required: false,
      default: false,
    },

    generalComments: {
      type: String,
      required: false,
      default: '-',
    },
  },
}
</script>
