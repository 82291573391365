var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('add-edit-form-component',{attrs:{"resource-type":"Deliveries","is-fullscreen":_vm.isMobileScreen,"is-read-only":_vm.isViewMode},on:{"saveResource":_vm.saveDeliveriesForm,"cancelForm":_vm.cancelDeliveriesForm}},[(_vm.isMobileScreen)?_c('template',{slot:"form-header"},[_c('deliveries-form-header-mobile',{attrs:{"is-view-mode":_vm.isViewMode,"is-date-time-change-allowed":_vm.isDateTimeChangeAllowed},on:{"cancel:deliveries-form":_vm.cancelDeliveriesForm,"toggle:edit-mode":_vm.toggleEditMode}})],1):_vm._e(),_c('template',{slot:"form-content"},[(_vm.videoProductElementsLoading)?_c('div',[_c('default-loader')],1):(_vm.isViewMode)?_c('deliveries-form-view',_vm._b({},'deliveries-form-view',_vm.viewData,false)):_c('div',{staticClass:"vms-deliveries__form"},[(!_vm.isMobileScreen)?_c('deliveries-form-header-desktop',{attrs:{"is-date-time-change-allowed":_vm.isDateTimeChangeAllowed}}):_vm._e(),_c('div',{staticClass:"vms-deliveries__form--group vd-padding-right-small vd-padding-left-small vd-padding-bottom-large"},[(_vm.isMobileScreen && !_vm.isDateTimeChangeAllowed)?_c('div',{staticClass:"vd-padding-bottom-small vd-red vd-text-small"},[_vm._v(" Modify duration or dates by using the calendar ")]):_vm._e(),_c('div',{staticClass:"vd-padding-bottom-30"},[_c('input-component',{attrs:{"type":"text","name":"deliveriesVideoName","label":"Video Name","disabled":_vm.videoNameDisabled,"required":true,"value":_vm.videoNameValue,"placeholder":"Choose a memorable name","container-custom-classes":[
                _vm.setInputValidationClass('videoName', _vm.isEditType) ]},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'videoName')}}})],1),_c('div',{staticClass:"vd-padding-bottom-30"},[_c('div',{class:[
                'vd-field vd-required',
                _vm.setInputValidationClass('revisions', _vm.isEditType) ]},[_c('label',[_vm._v("Revisions")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.revisions),expression:"revisions"}],attrs:{"id":"deliveriesRevisionsSelect","name":"revisions","required":"","disabled":!_vm.canUpdateRevisions},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.revisions=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.handleInputUpdate($event.target.value, 'revisions')}]}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(" Select revisions ")]),_vm._l((_vm.maxAllocatedRevisions),function(revision){return _c('option',{key:revision,domProps:{"value":revision}},[_vm._v(" "+_vm._s(revision)+" ")])})],2)])]),_c('product-element-extras',{attrs:{"id":"deliveriesAdditionalExtrasSelect","available-extras":_vm.videoProductElements,"product-elements-list":_vm.videoProductElements,"is-edit-type":_vm.isEditType,"hovered-extra":_vm.hoveredExtra,"selected-product-elements":_vm.selectedProductElements,"has-extras":_vm.hasExtras,"container-custom-classes":_vm.productElementExtrasClasses,"product-elements-select-label":"Production Elements"},on:{"update:selected-extras":_vm.updateSelectedExtras,"hover:selected-extras":_vm.handleExtraHover,"hover:remove-selected-extra":_vm.removeSelectedExtra}})],1),(_vm.isDateTimeChangeAllowed)?_c('div',{staticClass:"vms-deliveries__form--group vd-padding-right-small vd-padding-left-small"},[_c('div',{staticClass:"vd-padding-bottom-30"},[_c('div',{class:[
                'vd-field vd-required',
                _vm.setInputValidationClass('productionDate', _vm.isEditType) ]},[_c('label',[_vm._v("Production Date")]),_c('flat-pickr',{staticClass:"vd-border-grey vd-background-transparent vd-input vd-input-valid vd-input-filled vd-required",attrs:{"id":"deliveriesProductionDateInput","config":_vm.productionDateConfig,"placeholder":"Select a production date","name":"productionDate"},on:{"input":function($event){return _vm.handleInputUpdate($event, 'productionDate')}},model:{value:(_vm.productionDate),callback:function ($$v) {_vm.productionDate=$$v},expression:"productionDate"}})],1)]),_c('div',{staticClass:"vd-padding-bottom-30"},[_c('div',{ref:"duration"},[_c('v-select',{class:[
                  'vd-field vd-required vd-padding-bottom-30',
                  _vm.setInputValidationClass('duration', _vm.isEditType) ],attrs:{"id":"deliveriesDurationSelect","placeholder":"Select duration","persistent-placeholder":"","items":_vm.durationListOptions,"hide-selected":true,"required":true,"menu-props":_vm.menuProps,"label":"Duration","no-data-text":"No results to display"},on:{"change":function($event){return _vm.handleInputUpdate($event, 'duration')}},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1)])]):_vm._e(),_c('div',{staticClass:"vms-deliveries__form--group vd-padding-inline-small vd-margin-bottom-30"},[_c('text-editor',{class:['vd-field', _vm.generalComments ? 'vd-input-valid' : ''],attrs:{"id":"deliveriesGeneralCommentsTextArea","label":"General Comments","content":_vm.generalComments},on:{"update:text-editor-content":_vm.handleGeneralCommentsUpdated}})],1)],1)],1),_c('template',{slot:"additional-buttons"},[(_vm.canHoldJob)?_c('button',{class:[
          'vms-project__form--btn vd-btn-white',
          _vm.isMobileScreen
            ? 'vms-project__form--btn__mobile vd-btn-small vd-margin-right-small'
            : 'vd-btn-medium vd-margin-right-medium' ],attrs:{"disabled":_vm.isHoldButtonDisabled},on:{"click":_vm.handleClickHoldButton}},[(_vm.onHoldLoading)?_c('span',[_vm._v(" Loading... ")]):_c('span',[_vm._v(" Hold ")])]):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }