<template>
  <div class="row">
    <div :class="[isViewMode ? 'col-6' : 'col-10']">
      <div class="row no-gutters">
        <span
          :class="[
            'vms-deliveries__product-type--icon-form__mobile vd-margin-right-small vd-margin-top-extra-small',
            getVideoProductTypeIconClass(selectedVideoProduct.name),
          ]"
        ></span>
        <span class="vd-h3 vd-margin-top-extra-small">
          {{ selectedVideoProduct.name }}
        </span>
      </div>
    </div>

    <div :class="['vd-text-align-right', isViewMode ? 'col-6' : 'col-2']">
      <button
        v-if="isViewMode"
        id="toggleEditModeButton"
        class="vd-btn-grey vd-a vd-btn-small vd-margin-right-medium"
        @click="toggleEditMode"
      >
        Edit
      </button>
      <span
        id="cancelDeliveriesFormSpan"
        class="vd-icon-close vms-modal__close vd-margin-top-medium"
        @click="cancelDeliveriesForm"
      ></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IconsMixin from '@mixins/icons-mixin'
import SweetAlert from '@plugins/sweet-alert'

export default {
  mixins: [IconsMixin],

  props: {
    isViewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      selectedVideoProduct: 'project/delivery/selectedVideoProduct',
      isProjectOnHold: 'project/isProjectOnHold',
    }),
  },

  methods: {
    cancelDeliveriesForm() {
      this.$emit('cancel:deliveries-form')
    },

    toggleEditMode() {
      if (this.isProjectOnHold) {
        SweetAlert.fire({
          title: 'Project On Hold',
          html: `<br> Please take the project off hold before making changes on jobs.`,
          showCancelButton: false,
          confirmButtonText: 'Close',
        })
      } else {
        this.$emit('toggle:edit-mode')
      }
    },
  },
}
</script>
