<template>
  <div class="vd-lightest-grey">
    <div class="vd-text-align-right">
      <div
        v-if="deliveryDetail.used_revisions > 0"
        class="vd-display-inline-block"
      >
        <span
          v-for="usedRevision in deliveryDetail.used_revisions"
          :key="usedRevision"
          class="vd-icon-pencil-grey vms-deliveries__icons--revisions"
        ></span>
      </div>
      <div
        v-if="deliveryDetail.revisions_remaining > 0"
        class="vd-display-inline-block"
      >
        <span
          v-for="remainingRevision in deliveryDetail.revisions_remaining"
          :key="remainingRevision"
          class="vd-icon-pencil-blue vms-deliveries__icons--revisions"
        ></span>
      </div>
    </div>
    <span>Revisions</span>
  </div>
</template>

<script>
export default {
  props: {
    deliveryDetail: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
}
</script>
