<template>
  <div
    class="vms-deliveries__thumbnail-wrapper vd-border-radius-6 vd-margin-right-large vms-deliveries__thumbnail-wrapper--mobile"
  >
    <div
      id="toggleVideoPlayerDiv"
      v-custom-background-image="videoThumbnail"
      :class="[
        'vms-deliveries__thumbnail vd-position-relative vd-border-radius-5',
        hasVideo ? 'vms-deliveries__thumbnail--clickable' : '',
      ]"
      @click="toggleVideoPlayer"
    >
      <div>
        <text-popover
          class="vms-deliveries__product-type vms-deliveries__product-type--container vd-display-inline-block vd-position-absolute vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10 vd-border-radius-100 vd-box-shadow vd-background-white"
          :tooltip-text="deliveryDetail.type"
          :tooltip-icon="getVideoProductTypeIconClass(deliveryDetail.type)"
          custom-tooltip-class="vms-deliveries__product-type--icon-popover"
          :tooltip-id="`deliveryDetailIconTypeHover-${deliveryDetail.id}`"
          tooltip-placement="top"
        ></text-popover>

        <div
          class="vd-display-inline-block vms-deliveries__background--tint vms-deliveries__background--details vd-background-black vd-padding-right-extra-small vd-padding-left-extra-small vd-text-align-center"
        >
          <div class="vms-deliveries__text--details vd-position-absolute">
            <deliveries-remaining-revisions
              :delivery-detail="deliveryDetail"
            ></deliveries-remaining-revisions>

            <deliveries-duration
              v-if="video && video.duration > 0"
              :duration="video.duration"
              class="vd-margin-top-extra-small"
            ></deliveries-duration>
          </div>
        </div>
      </div>
    </div>

    <div
      class="vd-light-blue vd-margin-bottom-small vd-margin-top-small vd-padding-right-extra-large vd-position-relative"
    >
      <hero-star
        v-if="hasVideo"
        class="vms-deliveries__hero-star vd-margin-right-small"
        custom-class="vms-hero-star"
        :video="video"
        @star-updated="toggleVideoStar"
      ></hero-star>

      <span id="viewDeliverySpan" role="button" @click="viewDelivery">
        {{ deliveryDetail.name }}

        <span
          class="vms-deliveries__view vd-icon-arrow-right-grey-black vd-position-absolute"
        ></span>
      </span>
    </div>

    <deliveries-latest-revision-message
      v-if="canShowLatestRevisionMessage"
      custom-class="vd-text-small vd-display-flex"
    ></deliveries-latest-revision-message>

    <div
      class="row no-gutters vms-deliveries__actions--mobile vms-deliveries__actions--mobile-wrapper"
    >
      <span
        v-if="deliveryStatusClass"
        :class="[
          'vd-border-radius-50 vd-padding-bottom-small vd-padding-top-small vd-padding-left-medium vd-padding-right-medium',
          deliveryStatusClass.container,
          deliveryStatusClass.label,
        ]"
      >
        {{ deliveryDetail.status }}
      </span>

      <div
        class="row no-gutters vd-position-absolute vms-deliveries__actions--mobile-options"
      >
        <assigned-crew
          class="vd-margin-right-small"
          :assigned-crew="deliveryDetail.order_job_assignees"
          :max-user-display-limit="1"
        ></assigned-crew>

        <div
          v-if="!canDisplayEditForm"
          id="toggleDeliveriesDropdownDiv"
          v-click-outside="closeDeliveriesDropdown"
          class="vd-position-relative"
          @click="toggleDeliveriesDropdown"
        >
          <span
            class="vms-deliveries__actions--icon vms-deliveries__actions--icon__mobile vd-icon-three-dots-circle"
          ></span>
          <deliveries-dropdown
            v-show="showDeliveriesDropdown"
            :delivery-detail="deliveryDetail"
          ></deliveries-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import HeroStar from '@components/hero-star/hero-star'
import DeliveriesDuration from '@views/project/deliveries/components/deliveries-duration'
import DeliveriesRemainingRevisions from '@views/project/deliveries/components/deliveries-remaining-revisions'
import DeliveriesLatestRevisionMessage from '@views/project/deliveries/components/deliveries-latest-revision-message'
import DeliveriesDropdown from '@views/project/deliveries/components/deliveries-dropdown'
import TextPopover from '@components/tooltips/text-popover'
import StyleMixin from '@mixins/styles-mixin'
import IconsMixin from '@mixins/icons-mixin'
import AssignedCrew from '@components/assigned-crew/assigned-crew'

export default {
  directives: {
    ClickOutside,
  },

  components: {
    HeroStar,
    DeliveriesDuration,
    DeliveriesRemainingRevisions,
    DeliveriesDropdown,
    TextPopover,
    AssignedCrew,
    DeliveriesLatestRevisionMessage,
  },

  mixins: [StyleMixin, IconsMixin],

  props: {
    deliveryDetail: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    canDisplayEditForm: {
      type: Boolean,
      required: false,
      default: false,
    },

    deliveryStatusClass: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    video: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    videoThumbnail: {
      type: String,
      required: true,
      default: '',
    },

    hasVideo: {
      type: Boolean,
      required: false,
      default: false,
    },

    canShowLatestRevisionMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showDeliveriesDropdown: false,
    }
  },

  methods: {
    toggleDeliveriesDropdown() {
      this.showDeliveriesDropdown = !this.showDeliveriesDropdown
    },

    closeDeliveriesDropdown() {
      this.showDeliveriesDropdown = false
    },

    toggleVideoStar() {
      this.$emit('toggle:video-star')
    },

    viewDelivery() {
      this.$emit('view:delivery')
    },

    toggleVideoPlayer() {
      this.$emit('toggle:video-player', this.deliveryDetail.id)
    },
  },
}
</script>
