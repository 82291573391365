<template>
  <div
    :class="[
      'vd-padding-top-20 vd-padding-bottom-20 vd-border-light-grey vms-deliveries__wrapper',
      isMobileScreen ? 'vms-deliveries__wrapper--mobile' : '',
    ]"
  >
    <div class="vms-deliveries__content">
      <slot name="bulk-delivery"></slot>

      <deliveries-list-details-mobile
        v-if="isMobileScreen"
        v-bind="viewData"
        @toggle:video-star="toggleVideoJobStar"
        @toggle:video-player="toggleVideoPlayer"
        @view:delivery="viewDelivery"
      ></deliveries-list-details-mobile>

      <deliveries-list-details-desktop
        v-else
        v-bind="viewData"
        @toggle:video-star="toggleVideoJobStar"
        @toggle:video-player="toggleVideoPlayer"
        @view="viewDelivery"
      ></deliveries-list-details-desktop>
    </div>

    <edit-video-delivery-job-form
      v-if="canDisplayEditForm"
      :is-view-mode="isMobileScreen && isViewMode"
      :class="[!isMobileScreen ? 'vd-margin-top-large' : '']"
      @edit:delivery="isViewMode = false"
    ></edit-video-delivery-job-form>

    <deliveries-video-modal
      v-if="videoPlayerShow"
      :video="deliveryDetail.video"
    ></deliveries-video-modal>

    <video-info-modal
      v-if="canShowVideoInfoModal"
      :video-id="deliveryDetail.video.id"
    >
    </video-info-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty, isNil, isNull } from 'lodash'
import EditVideoDeliveryJobForm from '@views/project/components/forms/deliveries/deliveries-form'
import ImageConstants from '@configs/image-constants'
import DeliveriesVideoModal from '@views/project/deliveries/components/deliveries-video-modal'
import VideoInfoModal from '@views/videos/components/video-information-modal'
import DeliveriesListDetailsMobile from '@views/project/deliveries/components/deliveries-list-details-mobile'
import DeliveriesListDetailsDesktop from '@views/project/deliveries/components/deliveries-list-details-desktop'
import StyleMixin from '@mixins/styles-mixin'

export default {
  components: {
    EditVideoDeliveryJobForm,
    DeliveriesVideoModal,
    VideoInfoModal,
    DeliveriesListDetailsMobile,
    DeliveriesListDetailsDesktop,
  },

  mixins: [StyleMixin],

  provide: {
    formActionType: 'edit',
  },

  props: {
    deliveryDetail: {
      type: Object,
      require: true,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      isViewMode: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedVideoDeliveryJob: 'project/delivery/selectedVideoDeliveryJob',
      videoDeliveryJobEditFormOpen:
        'project/delivery/videoDeliveryJobEditFormOpen',
      showVideoPlayerModal: 'project/delivery/showVideoPlayerModal',
      showVideoInfoModal: 'videos/showVideoInfoModal',
      selectedModalVideoId: 'videos/selectedModalVideoId',
      selectedDeliveryId: 'project/delivery/selectedDeliveryId',
      isMobileScreen: 'common/isMobileScreen',
    }),

    videoThumbnail() {
      return this.deliveryDetail.video
        ? this.deliveryDetail.video.video_thumbnail_url
        : ImageConstants.defaultVideoThumbnailUrl
    },

    canDisplayEditForm() {
      return (
        this.videoDeliveryJobEditFormOpen &&
        this.selectedVideoDeliveryJob.id === this.deliveryDetail.id
      )
    },

    deliveryStatusClass() {
      return this.getStatusClass(
        'deliveryStatusClasses',
        this.deliveryDetail.status_id
      )
    },

    videoPlayerShow() {
      return (
        this.showVideoPlayerModal &&
        this.hasVideo &&
        this.selectedDeliveryId === this.deliveryDetail.id
      )
    },

    canShowVideoInfoModal() {
      return (
        this.hasVideo &&
        this.showVideoInfoModal &&
        this.selectedModalVideoId === this.deliveryDetail.video.id
      )
    },

    hasVideo() {
      return (
        !isEmpty(this.deliveryDetail.video) &&
        !isNil(this.deliveryDetail.video.s3_url)
      )
    },

    canShowLatestRevisionMessage() {
      if (isNull(this.deliveryDetail.video)) {
        return false
      }

      return !this.deliveryDetail.video.is_latest_revision_delivered
    },

    viewData() {
      return {
        deliveryDetail: this.deliveryDetail,
        canDisplayEditForm: this.canDisplayEditForm,
        deliveryStatusClass: this.deliveryStatusClass,
        hasVideo: this.hasVideo,
        videoThumbnail: this.videoThumbnail,
        video: this.deliveryDetail.video,
        canShowLatestRevisionMessage: this.canShowLatestRevisionMessage,
      }
    },
  },

  watch: {
    videoDeliveryJobEditFormOpen(newVal) {
      // reset viewMode
      if (!newVal) {
        this.isViewMode = false
      }
    },
  },

  methods: {
    ...mapActions({
      toggleVideoPlayerShowModal: 'project/delivery/toggleVideoPlayerShowModal',
      toggleVideoStar: 'project/delivery/toggleVideoStar',
      showEditVideoDeliveryJobForm:
        'project/delivery/showEditVideoDeliveryJobForm',
      showAddNewVideoDeliveryJobForm:
        'project/delivery/showAddNewVideoDeliveryJobForm',
      updateSelectedVideoDeliveryJobId:
        'project/delivery/updateSelectedVideoDeliveryJobId',
      updateSelectedVideoProductId:
        'project/delivery/updateSelectedVideoProductId',
    }),

    toggleVideoPlayer(id) {
      if (this.hasVideo) {
        this.toggleVideoPlayerShowModal(id)
      }
    },

    viewDelivery() {
      this.showEditVideoDeliveryJobForm(true)
      this.showAddNewVideoDeliveryJobForm(false)

      this.updateSelectedVideoDeliveryJobId(this.deliveryDetail.id)
      this.updateSelectedVideoProductId({
        product_id: this.deliveryDetail.product_id,
      })
      this.isViewMode = true
    },

    toggleVideoJobStar() {
      this.toggleVideoStar(this.deliveryDetail.id)
    },
  },
}
</script>
