<template>
  <div
    :class="[
      customClass,
      'vms-deliveries__latest-revision-message vd-red vd-text-strong vd-margin-block-extra-small',
    ]"
  >
    <i
      :class="[
        iconCustomClass,
        'vd-icon icon-triangle-exclamation vd-margin-right-extra-small',
      ]"
    ></i>
    <span
      >This revision of the video cannot be seen by the client yet - Deliver to
      sync</span
    >
  </div>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      required: false,
      default: '',
    },

    iconCustomClass: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
