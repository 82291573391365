<template>
  <div
    class="vms-dropdown vms-deliveries__actions--dropdown vd-box-shadow vd-background-white vd-border-radius-6"
  >
    <div>
      <div
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-update vd-padding-block-small vd-padding-inline-medium"
        @click="handleUpdateClick"
      >
        Update details
      </div>

      <div
        v-if="hasVideo"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-video-details vd-padding-block-small vd-padding-inline-medium"
        @click="toggleVideoInformationShowModal(deliveryDetail.video.id)"
      >
        Video info
      </div>

      <a
        v-if="videoUrl"
        :href="videoUrl"
        target="_blank"
        rel="noopener"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-view vd-black vd-padding-block-small vd-padding-inline-medium"
      >
        View
      </a>

      <a
        v-if="videoInternalUrl"
        :href="videoInternalUrl"
        target="_blank"
        rel="noopener"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-internal-review vd-black vd-padding-block-small vd-padding-inline-medium"
      >
        Internal review
      </a>

      <a
        v-if="canDisplayUploadOption"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-upload vd-black vd-padding-block-small vd-padding-inline-medium"
        :href="uploadVideoPageUrl"
        @click.prevent="handleUploadClick"
      >
        Upload
      </a>

      <div
        v-if="canDisplayMarkAsDelivered"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vd-padding-block-small vd-padding-inline-medium"
        @click="handleMarkAsDelivered"
      >
        Mark as delivered
      </div>

      <a
        v-if="canDisplayDeliverOption"
        :href="deliveryEmailPreviewUrl"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-deliver vd-black vd-padding-block-small vd-padding-inline-medium"
      >
        Deliver
      </a>

      <div
        v-if="isMobileScreen"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vd-padding-block-small vd-padding-inline-medium"
        @click="handleEodNotesClick"
      >
        Notes
      </div>

      <div
        v-if="isMobileScreen"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vd-padding-block-small vd-padding-inline-medium"
        @click="handleHoldUnholdClick"
      >
        {{ isOnHold ? 'Take off hold' : 'Put on hold' }}
      </div>

      <div
        v-if="canDisplayApproveOption"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-approve vd-padding-block-small vd-padding-inline-medium"
        @click="handleApproveClick"
      >
        Approve
      </div>

      <a
        v-if="canDisplayChangesOption"
        :href="orderJobChangePageUrl"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-changes vd-black vd-padding-block-small vd-padding-inline-medium"
      >
        Changes
      </a>

      <div
        v-if="canDisplayUnapproveOption"
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-unapprove vd-padding-block-small vd-padding-inline-medium"
        @click="handleUnapproveClick"
      >
        Unapprove
      </div>

      <div
        v-if="
          deliveryDetail.status_id === orderJobStatusConstant.ORDER_JOB_APPROVED
        "
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-archive vd-padding-block-small vd-padding-inline-medium"
        @click="handleArchiveClick"
      >
        Archive
      </div>

      <div
        class="vms-dropdown__list vms-deliveries__actions--dropdown-list vms-deliveries__action-delete vd-padding-block-small vd-padding-inline-medium"
        @click="handleDeleteVideoDeliveryClick"
      >
        Delete
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, isNil, includes } from 'lodash'
import SweetAlert from '@plugins/sweet-alert'
import AppConfig from '@configs/app-config'
import VmsConstants from '@configs/vms-constants'

export default {
  props: {
    deliveryDetail: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      orderJobStatusConstant: VmsConstants.orderJobStatuses,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      videoDeliveryJobEditFormOpen:
        'project/delivery/videoDeliveryJobEditFormOpen',
      videoDeliveryJobNewFormOpen:
        'project/delivery/videoDeliveryJobNewFormOpen',
      isProjectDraft: 'project/isProjectDraft',
      isMobileScreen: 'common/isMobileScreen',
      isProjectOnHold: 'project/isProjectOnHold',
    }),

    videoUrl() {
      return this.deliveryDetail.video?.video_landing_url || ''
    },

    videoInternalUrl() {
      return this.deliveryDetail.video?.video_landing_internal_url || ''
    },

    uploadVideoPageUrl() {
      if (!this.isProjectDraft) {
        return this.deliveryDetail.video
          ? `${AppConfig.vmsApiUrl}/videos/edit/${this.deliveryDetail.video.id}`
          : `${AppConfig.vmsApiUrl}/videos/add/${this.deliveryDetail.id}`
      }

      return null
    },

    deliveryEmailPreviewUrl() {
      return `${AppConfig.vmsApiUrl}/projects/${this.project.id}/view-delivery-email/${this.deliveryDetail.id}`
    },

    orderJobChangePageUrl() {
      return `${AppConfig.vmsApiUrl}/order-job-changes/${this.deliveryDetail.id}/index`
    },

    canDisplayChangesOption() {
      return (
        this.deliveryDetail.status_id !==
          this.orderJobStatusConstant.ORDER_JOB_BOOKED &&
        this.deliveryDetail.status_id !==
          this.orderJobStatusConstant.ORDER_JOB_APPROVED &&
        this.deliveryDetail.status_id !==
          this.orderJobStatusConstant.ORDER_JOB_ON_HOLD
      )
    },

    canDisplayApproveOption() {
      return (
        !isEmpty(this.deliveryDetail.video) &&
        this.deliveryDetail.status_id ===
          this.orderJobStatusConstant.ORDER_JOB_DELIVERED
      )
    },

    canDisplayUnapproveOption() {
      return (
        this.deliveryDetail.is_approved &&
        this.deliveryDetail.status_id !==
          this.orderJobStatusConstant.ORDER_JOB_ARCHIVED &&
        this.deliveryDetail.status_id !==
          this.orderJobStatusConstant.ORDER_JOB_ON_HOLD
      )
    },

    canDisplayDeliverOption() {
      return (
        !isEmpty(this.deliveryDetail.video) &&
        this.deliveryDetail.status_id !==
          this.orderJobStatusConstant.ORDER_JOB_ON_HOLD
      )
    },

    canDisplayUploadOption() {
      return (
        this.deliveryDetail.status_id !==
          this.orderJobStatusConstant.ORDER_JOB_ARCHIVED &&
        this.deliveryDetail.status_id !==
          this.orderJobStatusConstant.ORDER_JOB_ON_HOLD
      )
    },

    canDisplayMarkAsDelivered() {
      return includes(
        [
          this.orderJobStatusConstant.ORDER_JOB_DELIVERED,
          this.orderJobStatusConstant.ORDER_JOB_CHANGES,
          this.orderJobStatusConstant.ORDER_JOB_POST_PRODUCTION,
          this.orderJobStatusConstant.ORDER_JOB_CHANGES_UPLOADED,
          this.orderJobStatusConstant.ORDER_JOB_VIDEO_UPLOADED,
          this.orderJobStatusConstant.ORDER_JOB_CHANGES_SCHEDULED,
        ],
        this.deliveryDetail.status_id
      )
    },

    hasVideo() {
      return (
        !isEmpty(this.deliveryDetail.video) &&
        !isNil(this.deliveryDetail.video.s3_url)
      )
    },

    isOnHold() {
      return (
        this.deliveryDetail.status_id ===
        VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD
      )
    },
  },

  methods: {
    ...mapActions({
      showEditVideoDeliveryJobForm:
        'project/delivery/showEditVideoDeliveryJobForm',
      showAddNewVideoDeliveryJobForm:
        'project/delivery/showAddNewVideoDeliveryJobForm',
      updateSelectedVideoDeliveryJobId:
        'project/delivery/updateSelectedVideoDeliveryJobId',
      updateSelectedVideoProductId:
        'project/delivery/updateSelectedVideoProductId',
      approveVideoDeliveryJob: 'project/delivery/approveVideoDeliveryJob',
      deliverVideoJob: 'project/delivery/deliverVideoJob',
      unapproveVideoDeliveryJob: 'project/delivery/unapproveVideoDeliveryJob',
      deleteVideoDeliveryJob: 'project/delivery/deleteVideoDeliveryJob',
      archiveVideoDeliveryJob: 'project/delivery/archiveVideoDeliveryJob',
      sendActivateAndConfirmationEmail:
        'project/invoice/sendActivateAndConfirmationEmail',
      toggleVideoInformationShowModal: 'videos/toggleVideoInformationShowModal',
      holdVideoDeliveryJob: 'project/delivery/holdVideoDeliveryJob',
      unholdVideoDeliveryJob: 'project/delivery/unholdVideoDeliveryJob',
      setSelectedEodNoteTypeId: 'project/setSelectedEodNoteTypeId',
      toggleEodNotesModal: 'project/toggleEodNotesModal',
    }),

    handleUpdateClick() {
      if (this.isProjectOnHold) {
        SweetAlert.fire({
          title: 'Project On Hold',
          html: `<br> Please take the project off hold before making changes on jobs.`,
          showCancelButton: false,
          confirmButtonText: 'Close',
        })
      } else {
        if (
          this.videoDeliveryJobEditFormOpen ||
          this.videoDeliveryJobNewFormOpen
        ) {
          this.showWarningAlert()
        } else {
          this.openEditForm()
        }
      }
    },

    openEditForm() {
      this.showEditVideoDeliveryJobForm(true)
      this.showAddNewVideoDeliveryJobForm(false)

      this.updateSelectedVideoDeliveryJobId(this.deliveryDetail.id)
      this.updateSelectedVideoProductId({
        product_id: this.deliveryDetail.product_id,
        type: 'edit',
      })
    },

    showWarningAlert() {
      SweetAlert.fire({
        title: 'Unsaved Changes',
        html: `<br> You have unsaved changes in progress. <br><br>  Do you wish to discard these changes and open a new form?`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.openEditForm()
        }
      })
    },

    handleApproveClick() {
      this.updateSelectedVideoProductId(this.deliveryDetail.id)

      SweetAlert.fire({
        title: 'Approve',
        html: `<br> Are you sure you want to approve <br><br> <strong>${this.deliveryDetail.name}</strong>?`,
        showCancelButton: true,
        confirmButtonText: 'Approve',
      }).then((result) => {
        if (result.isConfirmed) {
          this.approveVideoDeliveryJob({
            project_id: this.project.id,
            order_job_id: this.deliveryDetail.id,
            includes: [
              'product_element_type',
              'video_job_video',
              'video_job_order_job_elements',
              'product_element',
            ],
            name: this.deliveryDetail.name,
          })
        }
      })
    },

    handleUnapproveClick() {
      this.updateSelectedVideoProductId(this.deliveryDetail.id)

      SweetAlert.fire({
        title: 'Unapprove',
        html: `<br> Are you sure you want to unapprove <br><br> <strong>${this.deliveryDetail.name}</strong>?`,
        showCancelButton: true,
        confirmButtonText: 'Unapprove',
      }).then((result) => {
        if (result.isConfirmed) {
          this.unapproveVideoDeliveryJob({
            project_id: this.project.id,
            order_job_id: this.deliveryDetail.id,
            includes: [
              'product_element_type',
              'video_job_video',
              'video_job_order_job_elements',
              'product_element',
            ],
            name: this.deliveryDetail.name,
          })
        }
      })
    },

    handleDeleteVideoDeliveryClick() {
      this.updateSelectedVideoProductId(this.deliveryDetail.id)

      SweetAlert.fire({
        title: 'Delete',
        html: `<br> Are you sure you want to delete <br><br> <strong>${this.deliveryDetail.name}</strong>?`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#ff3333',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteVideoDeliveryJob({
            project_id: this.project.id,
            order_job_id: this.deliveryDetail.id,
            includes: null,
            name: this.deliveryDetail.name,
          })
        }
      })
    },

    handleArchiveClick() {
      this.updateSelectedVideoProductId(this.deliveryDetail.id)

      SweetAlert.fire({
        title: 'Archive',
        html: `<br> Are you sure you want to archive <br><br> <strong>${this.deliveryDetail.name}</strong>?`,
        showCancelButton: true,
        confirmButtonText: 'Archive',
      }).then((result) => {
        if (result.isConfirmed) {
          this.archiveVideoDeliveryJob({
            project_id: this.project.id,
            order_job_id: this.deliveryDetail.id,
            includes: [
              'product_element_type',
              'video_job_video',
              'video_job_order_job_elements',
              'product_element',
            ],
            name: this.deliveryDetail.name,
          })
        }
      })
    },

    handleUploadClick(event) {
      if (this.project.has_draft_order || this.isProjectDraft) {
        SweetAlert.fire({
          title: 'Draft Project',
          html: `<br> Please confirm & activate your project. <br><br> Once the project is confirmed the video will be ready for use.`,
          showCancelButton: true,
          confirmButtonText: 'Confirm Project',
        }).then((result) => {
          if (result.isConfirmed) {
            this.sendActivateAndConfirmationEmail()
          }
        })
      } else {
        // Check if cmd/ctrl key is selected
        if (event.metaKey || event.ctrlKey) {
          window.open(this.uploadVideoPageUrl, '_blank')
        } else {
          window.open(this.uploadVideoPageUrl, '_self')
        }
      }
    },

    showProjectOnHoldAlert() {
      SweetAlert.fire({
        title: 'Project On Hold',
        html: `<br> Please take the project off hold before making changes on jobs.`,
        showCancelButton: false,
        confirmButtonText: 'Close',
      })
    },

    handleHoldUnholdClick() {
      if (this.isProjectOnHold) {
        this.showProjectOnHoldAlert()
      } else {
        const payload = {
          project_id: this.project.id,
          order_job_id: this.deliveryDetail.id,
          name: this.deliveryDetail.name,
          includes: null,
        }

        if (this.isOnHold) {
          this.unholdVideoDeliveryJob(payload)
        } else {
          this.holdVideoDeliveryJob(payload)
        }
      }
    },

    handleEodNotesClick() {
      this.setSelectedEodNoteTypeId(this.deliveryDetail.id)
      this.toggleEodNotesModal(true)
    },

    handleMarkAsDelivered() {
      this.updateSelectedVideoProductId(this.deliveryDetail.id)

      SweetAlert.fire({
        title: 'Mark as delivered',
        html: `<br>Are you sure you want to mark this job as delivered?<br><br><strong>${this.deliveryDetail.name}</strong><br><br>This will NOT send delivery email to client.`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deliverVideoJob({
            project_id: this.project.id,
            order_job_id: this.deliveryDetail.id,
            includes: [
              'product_element_type',
              'video_job_video',
              'video_job_order_job_elements',
              'product_element',
            ],
            name: this.deliveryDetail.name,
          })
        }
      })
    },
  },
}
</script>
