<template>
  <div
    class="vd-full-width vd-padding-left-small vd-margin-bottom-small vms-deliveries__product-type"
  >
    <div
      :class="[
        'vms-deliveries__product-type--icon-form vd-margin-right-small',
        getVideoProductTypeIconClass(selectedVideoProduct.name),
      ]"
    />
    <span
      v-show="bulkAddVideosConfirm"
      class="vms-deliveries__product-type--label"
      >Bulk Add -
    </span>
    <span class="vms-deliveries__product-type--label">{{
      selectedVideoProduct.name
    }}</span>
    <div
      v-if="!isDateTimeChangeAllowed"
      class="vd-padding-top-extra-small vd-padding-bottom-small vd-red vd-text-small"
    >
      Modify duration or dates by using the calendar
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IconsMixin from '@mixins/icons-mixin'

export default {
  mixins: [IconsMixin],

  props: {
    isDateTimeChangeAllowed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      selectedVideoProduct: 'project/delivery/selectedVideoProduct',
      bulkAddVideosConfirm: 'project/delivery/bulkAddVideosConfirm',
    }),
  },
}
</script>
