<template>
  <default-modal
    class="show"
    modal-dialog-class="vms-video-modal__dialog vd-padding-medium"
  >
    <div slot="modal-body" v-click-outside="closeModalOutside">
      <deliveries-latest-revision-message
        v-if="!video.is_latest_revision_delivered"
        custom-class="vd-text-align-center vd-margin-inline-small"
        icon-custom-class="vd-align-top"
      ></deliveries-latest-revision-message>

      <div
        class="vd-icon-close-round vms-video-modal__close-button vd-position-absolute vd-box-shadow vd-border-radius-100"
        @click="toggleVideoPlayerShowModal"
      ></div>
      <video-player :video-options="videoOptions"></video-player>
    </div>
  </default-modal>
</template>

<script>
import { mapActions } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import ClickOutside from 'vue-click-outside'
import VideoPlayer from '@components/video-player/video-player'
import DeliveriesLatestRevisionMessage from '@views/project/deliveries/components/deliveries-latest-revision-message'

export default {
  components: {
    DefaultModal,
    VideoPlayer,
    DeliveriesLatestRevisionMessage,
  },

  directives: {
    ClickOutside,
  },
  props: {
    video: {
      type: Object,
      required: true,
      default: () => {
        return {
          s3_url: '',
          video_thumbnail_url: '',
        }
      },
    },
  },

  data() {
    return {
      preventClickOutside: true,
    }
  },

  computed: {
    videoOptions() {
      return {
        controls: true,
        poster: this.video.video_thumbnail_url,
        sources: [
          {
            src: this.video.s3_url,
            type: 'video/mp4',
          },
        ],
        aspectRatio: '16:9',
        preload: true,
        autoplay: true,
      }
    },
  },

  methods: {
    ...mapActions({
      toggleVideoPlayerShowModal: 'project/delivery/toggleVideoPlayerShowModal',
    }),

    /*
      Added count to check if the modal can be opened.    
    */
    closeModalOutside() {
      if (!this.preventClickOutside) {
        this.toggleVideoPlayerShowModal()
      }
      this.preventClickOutside = !this.preventClickOutside
    },
  },
}
</script>
