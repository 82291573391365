<template>
  <div class="vd-lightest-grey">
    <span>{{ durationFormat }}</span>
  </div>
</template>

<script>
import momentTz from 'moment-timezone'

export default {
  props: {
    duration: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  computed: {
    durationFormat() {
      const secondsDuration = momentTz.duration(this.duration, 'seconds')

      // use padStart(2, 0) to append 0 for single digits. e.g. 2 seconds will be formatted as 02 seconds.
      const hours = secondsDuration.hours().toString().padStart(2, 0)
      const minutes = secondsDuration.minutes().toString().padStart(2, 0)
      const seconds = secondsDuration.seconds().toString().padStart(2, 0)
      const label = this.duration < 60 ? 'sec' : 'mins'

      return this.duration < 3600
        ? `${minutes}:${seconds}${label}`
        : `${hours}:${minutes}hrs`
    },
  },
}
</script>
