<template>
  <v-app>
    <add-edit-form-component
      resource-type="Deliveries"
      :is-fullscreen="isMobileScreen"
      :is-read-only="isViewMode"
      @saveResource="saveDeliveriesForm"
      @cancelForm="cancelDeliveriesForm"
    >
      <template v-if="isMobileScreen" slot="form-header">
        <deliveries-form-header-mobile
          :is-view-mode="isViewMode"
          :is-date-time-change-allowed="isDateTimeChangeAllowed"
          @cancel:deliveries-form="cancelDeliveriesForm"
          @toggle:edit-mode="toggleEditMode"
        ></deliveries-form-header-mobile>
      </template>

      <template slot="form-content">
        <div v-if="videoProductElementsLoading">
          <default-loader></default-loader>
        </div>

        <!-- view only -->
        <deliveries-form-view
          v-else-if="isViewMode"
          v-bind="viewData"
        ></deliveries-form-view>

        <!-- editable -->
        <div v-else class="vms-deliveries__form">
          <deliveries-form-header-desktop
            v-if="!isMobileScreen"
            :is-date-time-change-allowed="isDateTimeChangeAllowed"
          ></deliveries-form-header-desktop>

          <!-- form fields -->
          <div
            class="vms-deliveries__form--group vd-padding-right-small vd-padding-left-small vd-padding-bottom-large"
          >
            <div
              v-if="isMobileScreen && !isDateTimeChangeAllowed"
              class="vd-padding-bottom-small vd-red vd-text-small"
            >
              Modify duration or dates by using the calendar
            </div>
            <div class="vd-padding-bottom-30">
              <input-component
                type="text"
                name="deliveriesVideoName"
                label="Video Name"
                :disabled="videoNameDisabled"
                :required="true"
                :value="videoNameValue"
                placeholder="Choose a memorable name"
                :container-custom-classes="[
                  setInputValidationClass('videoName', isEditType),
                ]"
                @formInput="handleInputUpdate($event, 'videoName')"
              ></input-component>
            </div>

            <div class="vd-padding-bottom-30">
              <div
                :class="[
                  'vd-field vd-required',
                  setInputValidationClass('revisions', isEditType),
                ]"
              >
                <label>Revisions</label>

                <select
                  id="deliveriesRevisionsSelect"
                  v-model="revisions"
                  name="revisions"
                  required
                  :disabled="!canUpdateRevisions"
                  @change="handleInputUpdate($event.target.value, 'revisions')"
                >
                  <option :value="null" disabled selected>
                    Select revisions
                  </option>
                  <option
                    v-for="revision in maxAllocatedRevisions"
                    :key="revision"
                    :value="revision"
                  >
                    {{ revision }}
                  </option>
                </select>
              </div>
            </div>

            <product-element-extras
              id="deliveriesAdditionalExtrasSelect"
              :available-extras="videoProductElements"
              :product-elements-list="videoProductElements"
              :is-edit-type="isEditType"
              :hovered-extra="hoveredExtra"
              :selected-product-elements="selectedProductElements"
              :has-extras="hasExtras"
              :container-custom-classes="productElementExtrasClasses"
              product-elements-select-label="Production Elements"
              @update:selected-extras="updateSelectedExtras"
              @hover:selected-extras="handleExtraHover"
              @hover:remove-selected-extra="removeSelectedExtra"
            ></product-element-extras>
          </div>

          <div
            v-if="isDateTimeChangeAllowed"
            class="vms-deliveries__form--group vd-padding-right-small vd-padding-left-small"
          >
            <div class="vd-padding-bottom-30">
              <div
                :class="[
                  'vd-field vd-required',
                  setInputValidationClass('productionDate', isEditType),
                ]"
              >
                <label>Production Date</label>

                <flat-pickr
                  id="deliveriesProductionDateInput"
                  v-model="productionDate"
                  :config="productionDateConfig"
                  class="vd-border-grey vd-background-transparent vd-input vd-input-valid vd-input-filled vd-required"
                  placeholder="Select a production date"
                  name="productionDate"
                  @input="handleInputUpdate($event, 'productionDate')"
                >
                </flat-pickr>
              </div>
            </div>

            <div class="vd-padding-bottom-30">
              <div ref="duration">
                <v-select
                  id="deliveriesDurationSelect"
                  v-model="duration"
                  placeholder="Select duration"
                  persistent-placeholder
                  :class="[
                    'vd-field vd-required vd-padding-bottom-30',
                    setInputValidationClass('duration', isEditType),
                  ]"
                  :items="durationListOptions"
                  :hide-selected="true"
                  :required="true"
                  :menu-props="menuProps"
                  label="Duration"
                  no-data-text="No results to display"
                  @change="handleInputUpdate($event, 'duration')"
                >
                </v-select>
              </div>
            </div>
          </div>

          <div
            class="vms-deliveries__form--group vd-padding-inline-small vd-margin-bottom-30"
          >
            <text-editor
              id="deliveriesGeneralCommentsTextArea"
              :class="['vd-field', generalComments ? 'vd-input-valid' : '']"
              label="General Comments"
              :content="generalComments"
              @update:text-editor-content="handleGeneralCommentsUpdated"
            ></text-editor>
          </div>
        </div>
      </template>

      <template slot="additional-buttons">
        <button
          v-if="canHoldJob"
          :class="[
            'vms-project__form--btn vd-btn-white',
            isMobileScreen
              ? 'vms-project__form--btn__mobile vd-btn-small vd-margin-right-small'
              : 'vd-btn-medium vd-margin-right-medium',
          ]"
          :disabled="isHoldButtonDisabled"
          @click="handleClickHoldButton"
        >
          <span v-if="onHoldLoading"> Loading... </span>
          <span v-else> Hold </span>
        </button>
      </template>
    </add-edit-form-component>
  </v-app>
</template>

<style>
@import '~flatpickr/dist/flatpickr.css';
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddEditFormComponent from '@views/project/components/forms/form-main'
import SweetAlert from '@plugins/sweet-alert'
import InputComponent from '@components/form/form-input'
import FormMixin from '@mixins/forms-mixin'
import IconsMixin from '@mixins/icons-mixin'
import {
  required,
  minLength,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators'
import momentTz from 'moment-timezone'
import FlatPickr from 'vue-flatpickr-component'
import DurationsConstants from '@configs/all-durations'
import VmsConstants from '@configs/vms-constants'
import ProductElementExtras from '@views/project/components/forms/components/product-element-extras'
import {
  join,
  isEmpty,
  find,
  remove,
  forEach,
  head,
  isEqual,
  sortBy,
  isNil,
  map,
  entries,
} from 'lodash'
import DefaultLoader from '@components/loaders/default-loader'
import TextEditor from '@components/text-editor/text-editor'
import ApiCustomIncludes from '@configs/api-custom-includes'
import DeliveriesFormView from './components/deliveries-form-view'
import DeliveriesFormHeaderMobile from './components/deliveries-form-header-mobile'
import DeliveriesFormHeaderDesktop from './components/deliveries-form-header-desktop'
import Vuetify from 'vuetify'

export default {
  components: {
    InputComponent,
    AddEditFormComponent,
    FlatPickr,
    ProductElementExtras,
    DefaultLoader,
    TextEditor,
    DeliveriesFormView,
    DeliveriesFormHeaderMobile,
    DeliveriesFormHeaderDesktop,
  },

  mixins: [FormMixin, IconsMixin],

  vuetify: new Vuetify(),

  props: {
    isViewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      videoName: '',
      revisions: 2,
      productionDate: null,
      duration: null,
      generalComments: '',
      maxAllocatedRevisions: 5,
      durationList: DurationsConstants.durations,
      hoveredExtra: null,
      selectedProductElements: [],
      selectedExtras: [],
      onHoldLoading: false,
      menuProps: { closeOnClick: true },
    }
  },

  validations: {
    videoName: {
      required,
      minLength: minLength(1),
    },

    revisions: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(5),
    },

    productionDate: {
      required,
    },

    duration: {
      required,
    },
  },

  inject: ['formActionType'],

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      selectedVideoProduct: 'project/delivery/selectedVideoProduct',
      videoProductElementsLoading:
        'project/delivery/videoProductElementsLoading',
      videoProductElements: 'project/delivery/videoProductElements',
      selectedVideoDeliveryJob: 'project/delivery/selectedVideoDeliveryJob',
      bulkAddVideosConfirm: 'project/delivery/bulkAddVideosConfirm',
      bulkAddVideosNames: 'project/delivery/bulkAddVideosNames',
      bulkAddVideosQuantity: 'project/delivery/bulkAddVideosQuantity',
      isMobileScreen: 'common/isMobileScreen',
    }),

    isEditType() {
      return this.formActionType === 'edit'
    },

    isFormInvalid() {
      return this.$v.$invalid
    },

    productionDateConfig() {
      return {
        enableTime: false,
        allowInput: true,
        altFormat: 'd M Y',
        dateFormat: 'd M Y',
        altInput: true,
        disableMobile: 'true',
        minDate: !this.isEditType ? 'today' : null,
      }
    },

    videoNameValue() {
      if (this.bulkAddVideosConfirm) {
        return !isEmpty(this.bulkAddVideosNames)
          ? head(this.bulkAddVideosNames)
          : this.project.name
      } else {
        return this.isEditType
          ? this.selectedVideoDeliveryJob
            ? this.selectedVideoDeliveryJob.name
            : ''
          : ''
      }
    },

    videoNameDisabled() {
      return this.bulkAddVideosConfirm && !isEmpty(this.bulkAddVideosNames)
    },

    hasExtras() {
      return this.selectedVideoDeliveryJob
        ? !isEmpty(this.selectedVideoDeliveryJob.order_job_elements)
        : false
    },

    /**
     * Set the form payload based on the form action type
     */
    formPayload() {
      const payload = {
        product_id: this.selectedVideoProduct.product_id,
        name: this.videoName,
        allocated_revisions: this.revisions,
        production_date: momentTz(this.productionDate, 'DD MMM YYYY').format(
          'YYYY-MM-DD'
        ),
        external_note: this.generalComments,
        duration: this.duration,
      }

      // Get IDs of the current selected product element types
      const selectedProductElementTypeIds = []
      const selectedOrderJobElementIds = []

      forEach(this.selectedProductElements, (selectedProductElement) => {
        selectedProductElementTypeIds.push(selectedProductElement.id)

        if (this.isEditType) {
          const orderJobElement = find(
            this.selectedVideoDeliveryJob.order_job_elements,
            {
              product_element: {
                product_element_type: {
                  id: selectedProductElement.id,
                },
              },
            }
          )

          selectedOrderJobElementIds.push(orderJobElement.id)
        }
      })

      payload['additional_extras'] = !isEmpty(this.selectedExtras)
        ? join(this.selectedExtras)
        : ''

      if (this.isEditType) {
        const defaultOrderJobElement = find(
          this.selectedVideoDeliveryJob.order_job_elements,
          {
            product_element: {
              id: this.selectedVideoDeliveryJob.primary_product_element_id,
            },
          }
        )

        if (defaultOrderJobElement) {
          selectedOrderJobElementIds.push(defaultOrderJobElement.id)
        }

        // Merge selected extras and current product elements in array
        payload['order_job_element_ids'] = join(selectedOrderJobElementIds)
      } else {
        // Merge selected extras and current product elements in array
        payload['product_element_type_ids'] = join(
          selectedProductElementTypeIds
        )
      }

      if (this.bulkAddVideosConfirm) {
        payload['bulk_add_videos_quantity'] = this.bulkAddVideosQuantity
        payload['bulk_add_videos_names'] = this.bulkAddVideosNames
      }

      return payload
    },

    canUpdateField() {
      if (this.isViewMode) {
        return false
      }

      if (this.isEditType && this.selectedVideoDeliveryJob.production_date) {
        const productionDate = momentTz.unix(
          this.selectedVideoDeliveryJob.production_date
        )

        return (
          momentTz(productionDate).isSameOrAfter(momentTz()) &&
          this.selectedVideoDeliveryJob.status_id !==
            VmsConstants.orderJobStatuses.ORDER_JOB_APPROVED &&
          this.selectedVideoDeliveryJob.status_id !==
            VmsConstants.orderJobStatuses.ORDER_JOB_ARCHIVED
        )
      }

      return true
    },

    canUpdateRevisions() {
      return (
        this.canUpdateField &&
        (!this.selectedVideoDeliveryJob ||
          (this.selectedVideoDeliveryJob &&
            this.selectedVideoDeliveryJob.allocated_revisions === null))
      )
    },

    changedPayload() {
      return {
        videoName: this.videoName,
        revisions: this.revisions,
        productionDate: this.productionDate,
        duration: parseInt(this.duration),
        generalComments: this.generalComments,
        selectedExtras: join(sortBy(this.selectedExtras)),
        selectedProductElements: join(
          sortBy(
            this.selectedProductElements?.map((selectedProductElement) => {
              return selectedProductElement.id
            })
          )
        ),
      }
    },

    viewData() {
      return {
        videoNameValue: this.videoNameValue,
        revisions: this.revisions,
        productionDate: this.productionDate,
        duration: this.durationListLabel,
        videoProductElements: this.videoProductElements,
        isEditType: this.isEditType,
        selectedProductElements: this.selectedProductElements,
        hasExtras: this.hasExtras,
        generalComments: this.generalComments,
      }
    },

    productElementExtrasClasses() {
      return (!isEmpty(this.selectedExtras) ||
        !isEmpty(this.selectedProductElements)) &&
        !this.isViewMode
        ? 'vd-input-valid'
        : ''
    },

    durationListOptions() {
      return map(entries(DurationsConstants.durations), ([key, value]) => ({
        text: value,
        value: Number(key),
      }))
    },

    durationListLabel() {
      return this.durationList[this.duration]
    },

    isVideoDeliveryJobOnHold() {
      return (
        this.selectedVideoDeliveryJob?.status_id ===
        VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD
      )
    },

    isDateTimeChangeAllowed() {
      return (
        !this.isEditType ||
        this.project.project_status_id ===
          VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT ||
        this.isVideoDeliveryJobOnHold
      )
    },

    isHoldButtonDisabled() {
      return (
        isEmpty(this.videoName) ||
        this.onHoldLoading ||
        (this.isVideoDeliveryJobOnHold &&
          isEqual(this.defaultPayload, this.changedPayload))
      )
    },

    canHoldJob() {
      if (
        !this.bulkAddVideosConfirm &&
        ((!isNil(this.selectedVideoDeliveryJob) &&
          this.isVideoDeliveryJobOnHold) ||
          !this.isEditType)
      ) {
        return true
      }

      return false
    },
  },

  watch: {
    isFormInvalid(val) {
      this.updateFormSaveBtnDisabled(val)
    },

    changedPayload(val) {
      this.updateFormSaveBtnDisabled(
        (isEqual(this.defaultPayload, val) &&
          this.selectedVideoDeliveryJob?.status_id !==
            VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD) ||
          this.isFormInvalid
      )
    },
  },

  mounted() {
    this.getVideoProductElements({
      product_id: this.selectedVideoProduct.product_id,
      includes: ['product_element_type'],
    })

    if (this.selectedVideoDeliveryJob && this.isEditType) {
      this.videoName = this.selectedVideoDeliveryJob.name
      this.revisions =
        this.selectedVideoDeliveryJob?.allocated_revisions || null
      this.productionDate = this.selectedVideoDeliveryJob.production_date
        ? momentTz
            .unix(this.selectedVideoDeliveryJob.production_date)
            .format('DD MMM YYYY')
        : null
      this.duration = this.selectedVideoDeliveryJob?.calendar_time || null
      this.generalComments = isEmpty(
        this.selectedVideoDeliveryJob.external_note
      )
        ? null
        : this.selectedVideoDeliveryJob.external_note

      this.updateSelectedProductElements()

      if (this.isFormInvalid) {
        this.updateFormSaveBtnDisabled(true)
      }

      // run validation when in edit mode
      this.$v.$touch()
    }

    this.initDefaultPayload()
  },

  methods: {
    ...mapActions({
      showAddNewVideoDeliveryJobForm:
        'project/delivery/showAddNewVideoDeliveryJobForm',
      showEditVideoDeliveryJobForm:
        'project/delivery/showEditVideoDeliveryJobForm',
      updateSelectedVideoProductId:
        'project/delivery/updateSelectedVideoProductId',
      createNewVideoDeliveryJob: 'project/delivery/createNewVideoDeliveryJob',
      updateFormSaveBtnDisabled: 'project/updateFormSaveBtnDisabled',
      getVideoProductElements: 'project/delivery/getVideoProductElements',
      updateVideoDeliveryJob: 'project/delivery/updateVideoDeliveryJob',
      setBulkAddVideosConfirm: 'project/delivery/setBulkAddVideosConfirm',
    }),

    /**
     * Populate the selected product elements based without the primary product element
     */
    updateSelectedProductElements() {
      forEach(
        this.selectedVideoDeliveryJob.order_job_elements,
        (orderJobElement) => {
          if (
            this.selectedVideoDeliveryJob.primary_product_element_id !==
            orderJobElement.product_element.id
          ) {
            this.selectedProductElements.push(
              orderJobElement.product_element.product_element_type
            )
          }
        }
      )
    },

    /**
     * Save the deliveries form depending on the form action type
     */
    saveDeliveriesForm() {
      if (this.isEditType) {
        this.updateVideoDeliveryJob({
          project_id: this.project.id,
          order_job_id: this.selectedVideoDeliveryJob.id,
          includes: ApiCustomIncludes.deliveriesList,
          payload: this.formPayload,
        })
      } else {
        this.createNewVideoDeliveryJob({
          project_id: this.project.id,
          includes: null,
          payload: this.formPayload,
        })
      }
    },

    cancelDeliveriesForm() {
      if (this.isViewMode) {
        if (this.isEditType) {
          this.showEditVideoDeliveryJobForm(false)
        } else {
          this.showAddNewVideoDeliveryJobForm(false)
        }
        return
      }

      const vm = this

      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to cancel your changes?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (vm.isEditType) {
            this.showEditVideoDeliveryJobForm(false)
          } else {
            this.showAddNewVideoDeliveryJobForm(false)
          }

          this.updateSelectedVideoProductId({
            product_id: null,
            type: 'create',
          })

          this.setBulkAddVideosConfirm(false)
        }
      })
    },

    updateSelectedExtras(selectedExtras) {
      this.selectedExtras = selectedExtras
    },

    handleExtraHover(productElement) {
      this.hoveredExtra = productElement ? productElement : null

      // to re-render the v-for without reloading the whole page
      this.$forceUpdate()
    },

    removeSelectedExtra(productElement) {
      // Check if the product element being removed is a current selected product element associated with the current project
      const isSelectedProductElements =
        find(this.selectedProductElements, {
          id: productElement.id,
        }) !== undefined

      if (isSelectedProductElements) {
        remove(this.selectedProductElements, {
          id: productElement.id,
        })
      }

      this.$forceUpdate() // to re-render the v-for without reloading the whole page
    },

    handleGeneralCommentsUpdated(comments) {
      this.generalComments = comments
    },

    initDefaultPayload() {
      this.defaultPayload = {
        videoName: this.videoName,
        revisions: this.revisions,
        productionDate: this.productionDate,
        duration: parseInt(this.duration),
        generalComments: this.generalComments,
        selectedExtras: join(sortBy(this.selectedExtras)),
        selectedProductElements: join(
          sortBy(
            this.selectedProductElements?.map((selectedProductElement) => {
              return selectedProductElement.id
            })
          )
        ),
      }
    },

    toggleEditMode() {
      this.$emit('edit:delivery')
    },

    handleClickHoldButton() {
      this.onHoldLoading = true

      let formPayloadData = this.formPayload
      let productionDate = !isEmpty(this.productionDate)
        ? momentTz(this.productionDate, 'DD MMM YYYY').format('YYYY-MM-DD')
        : null

      formPayloadData['on_hold'] = 1
      formPayloadData['production_date'] = productionDate

      if (this.isEditType) {
        this.updateVideoDeliveryJob({
          project_id: this.project.id,
          order_job_id: this.selectedVideoDeliveryJob.id,
          includes: ApiCustomIncludes.deliveriesList,
          payload: this.formPayload,
        })
      } else {
        this.createNewVideoDeliveryJob({
          project_id: this.project.id,
          includes: null,
          payload: this.formPayload,
        })
      }
    },
  },
}
</script>
