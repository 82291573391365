<template>
  <div class="vd-full-width">
    <div
      class="vms-deliveries__header vd-padding-bottom-medium vd-padding-left-small row no-gutters"
    >
      <div class="vd-margin-right-small">
        <hero-star
          v-if="hasVideo"
          class="vms-deliveries__hero-star vd-margin-right-extra-small"
          custom-class="vms-hero-star"
          :video="video"
          @star-updated="toggleVideoStar"
        ></hero-star>

        <strong>{{ deliveryDetail.name }}</strong>
      </div>

      <assigned-crew
        class="vd-margin-right-small"
        :assigned-crew="deliveryDetail.order_job_assignees"
      ></assigned-crew>

      <clipboard-copy
        v-if="video"
        container-class="vd-margin-right-small"
        copy-text="Copy Video Link"
        button-class="vms-deliveries__header--button row no-gutters vd-border-radius-10 vd-padding-inline-small vd-background-lightest-grey vd-dark-grey vd-border-lightest-grey small"
        :show-text="false"
        :show-as-button="true"
        :show-icon="true"
        :copy-content="video.video_landing_url"
      >
      </clipboard-copy>

      <div class="text-center vd-margin-right-small">
        <text-popover
          :tooltip-text="
            deliveryDetail.external_note ||
            `<em class='vd-dark-grey'>No comments provided</em>`
          "
          custom-tooltip-class="vms-deliveries__actions--icon vms-deliveries__actions--icon__hovered"
          tooltip-icon="vd-icon-doc"
          tooltip-trigger="click blur"
          popover-custom-class="vms-project__popover"
          :tooltip-id="`deliveryDetailExternalNoteHover-${deliveryDetail.id}`"
        ></text-popover>
      </div>

      <button
        :class="[
          'vms-deliveries__header--button vd-border-radius-10 vd-padding-block-extra-small vd-padding-inline-small vd-margin-right-small',
          isOnHold
            ? 'vd-background-cream vd-yellow vd-border-yellow'
            : 'vd-background-lightest-grey vd-dark-grey vd-border-lightest-grey',
        ]"
        @click="handleDeliveryHold"
      >
        <i class="vd-align-middle vd-icon icon-circle-pause"></i>

        <span
          v-if="canShowHoldLoading(deliveryDetail.id)"
          class="vd-align-middle vd-margin-left-extra-small vd-text-small"
        >
          Loading...
        </span>

        <span
          v-else
          class="vd-align-middle vd-margin-left-extra-small vd-text-small"
        >
          {{ isOnHold ? 'On Hold' : 'Hold' }}
        </span>

        <span
          v-if="isOnHold"
          class="vd-margin-left-extra-small vd-black vd-text-small"
          >&#10005;</span
        >
      </button>

      <button
        :class="[
          'vd-position-relative vms-deliveries__header--button vd-padding-block-extra-small vd-border-radius-10 vd-padding-inline-small vd-margin-right-small vd-background-light-blue vd-white vd-border-light-blue',
          deliveryDetail.has_eod_notes ? 'vd-padding-right-medium' : '',
        ]"
        @click="handleEodNotesClick"
      >
        <i class="vd-align-middle vd-icon icon-chat"></i>
        <span
          :class="[
            'vd-align-middle vd-margin-left-extra-small vd-text-small',
            deliveryDetail.has_eod_notes ? 'vd-red-dot' : '',
          ]"
        >
          Notes
        </span>
      </button>

      <div
        v-if="!canDisplayEditForm"
        id="toggleDeliveriesDropdownDiv"
        v-click-outside="closeDeliveriesDropdown"
        class="vms-deliveries__actions--container vd-position-relative vd-margin-right-small"
        @click="toggleDeliveriesDropdown"
      >
        <span
          class="vms-deliveries__actions--icon vms-deliveries__actions--icon__hovered vd-icon-three-dots-circle"
        ></span>

        <deliveries-dropdown
          v-show="showDeliveriesDropdown"
          :delivery-detail="deliveryDetail"
          class="vd-margin-top-small"
        ></deliveries-dropdown>
      </div>

      <deliveries-latest-revision-message
        v-if="canShowLatestRevisionMessage"
        custom-class="vd-text-small vd-display-flex"
      ></deliveries-latest-revision-message>
    </div>

    <div class="vms-deliveries__content">
      <div
        class="vms-deliveries__content--column vms-deliveries__thumbnail-wrapper vd-border-radius-6 vd-margin-right-extra-large vd-margin-left-small"
      >
        <div
          id="toggleVideoPlayerDiv"
          v-custom-background-image="videoThumbnail"
          :class="[
            'vms-deliveries__thumbnail vd-position-relative vd-border-radius-5',
            hasVideo ? 'vms-deliveries__thumbnail--clickable' : '',
          ]"
          @click="toggleVideoPlayer"
        >
          <div>
            <text-popover
              class="vms-deliveries__product-type vms-deliveries__product-type--container vd-display-inline-block vd-position-absolute vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10 vd-border-radius-100 vd-box-shadow vd-background-white"
              :tooltip-text="deliveryDetail.type"
              :tooltip-icon="getVideoProductTypeIconClass(deliveryDetail.type)"
              custom-tooltip-class="vms-deliveries__product-type--icon-popover"
              :tooltip-id="`deliveryDetailIconTypeHover-${deliveryDetail.id}`"
              tooltip-placement="top"
            ></text-popover>

            <div
              class="vd-display-inline-block vms-deliveries__background--tint vms-deliveries__background--details vd-background-black vd-padding-right-extra-small vd-padding-left-extra-small vd-text-align-center"
            >
              <div class="vms-deliveries__text--details vd-position-absolute">
                <deliveries-remaining-revisions
                  :delivery-detail="deliveryDetail"
                ></deliveries-remaining-revisions>

                <deliveries-duration
                  v-if="video && video.duration > 0"
                  :duration="video.duration"
                  class="vd-margin-top-extra-small"
                ></deliveries-duration>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="vms-deliveries__content--column vms-deliveries__production-date vd-margin-right-20 vd-margin-top-large"
      >
        <div>
          <span class="vd-dark-grey vd-h5">Prod. Date:</span>
          <span v-if="deliveryDetail.production_date">
            {{ isOnHold ? '(TBC)' : '' }}
            {{
              deliveryDetail.production_date | unixToFormat('ddd, D MMM YYYY')
            }}
          </span>
          <span v-else>TBC</span>
        </div>
        <div>
          <span class="vd-dark-grey vd-h5">Duration:</span>
          <span v-if="deliveryDetail.calendar_time">
            {{ isOnHold ? '(TBC)' : '' }}
            {{ deliveryDurationLabel }}
          </span>
          <span v-else>TBC</span>
        </div>
      </div>

      <div
        class="vms-deliveries__content--column vms-deliveries__extras vd-margin-right-20"
      >
        <span class="vd-dark-grey vd-h5">Production Elements</span>
        <div
          v-if="deliveryDetail.order_job_elements"
          class="vms-deliveries__extras--container vd-padding-top-extra-small vd-h5"
        >
          <div
            v-for="(
              orderJobElement, index
            ) in deliveryDetail.order_job_elements"
            :key="index"
            class="vd-margin-10 vd-margin-left-0 vd-padding-bottom-small"
          >
            <span
              v-if="orderJobElement.product_element"
              class="vd-border-radius-50 vd-background-lightest-grey vd-padding-10"
            >
              {{ orderJobElement.product_element.product_element_type.name }}
            </span>
          </div>
        </div>
      </div>

      <div
        class="vms-deliveries__content--column vms-deliveries__status vd-margin-right-20"
      >
        <span class="vd-dark-grey vd-h5">Status</span>
        <div class="vd-h5 vd-margin-top-medium">
          <span
            :class="[
              'vd-border-radius-50 vd-padding-top-10 vd-padding-bottom-10 vd-padding-left-medium vd-padding-right-medium',
              deliveryStatusClass.container,
              deliveryStatusClass.label,
            ]"
          >
            {{ deliveryDetail.status }}
          </span>
        </div>
      </div>

      <div
        class="vms-deliveries__content--column vms-deliveries__link-pre-production vd-margin-right-20"
      >
        <link-pre-production
          :linked-pre-productions="deliveryDetail.pre_production_jobs"
          :linking-job-id="deliveryDetail.id"
          :is-on-hold="isOnHold"
          @update:selected-pre-productions="addPreProduction"
          @remove:selected-pre-production="removePreProduction"
        >
        </link-pre-production>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import ClipboardCopy from '@components/clipboard-copy/clipboard-copy'
import DeliveriesDropdown from '@views/project/deliveries/components/deliveries-dropdown'
import DeliveriesDuration from '@views/project/deliveries/components/deliveries-duration'
import DeliveriesLatestRevisionMessage from '@views/project/deliveries/components/deliveries-latest-revision-message'
import DeliveriesRemainingRevisions from '@views/project/deliveries/components/deliveries-remaining-revisions'
import HeroStar from '@components/hero-star/hero-star'
import LinkPreProduction from '@views/project/components/forms/components/link-pre-production'
import TextPopover from '@components/tooltips/text-popover'
import momentTz from 'moment-timezone'
import ApiCustomIncludes from '@configs/api-custom-includes'
import StyleMixin from '@mixins/styles-mixin'
import IconsMixin from '@mixins/icons-mixin'
import FiltersMixin from '@mixins/filters-mixin'
import { map, join } from 'lodash'
import VmsConstants from '@configs/vms-constants'
import AssignedCrew from '@components/assigned-crew/assigned-crew'
import DurationsConstants from '@configs/all-durations'
import SweetAlert from '@plugins/sweet-alert'

export default {
  directives: {
    ClickOutside,
  },

  components: {
    ClipboardCopy,
    DeliveriesDuration,
    DeliveriesRemainingRevisions,
    DeliveriesDropdown,
    HeroStar,
    LinkPreProduction,
    TextPopover,
    AssignedCrew,
    DeliveriesLatestRevisionMessage,
  },

  mixins: [StyleMixin, FiltersMixin, IconsMixin],

  props: {
    deliveryDetail: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    canDisplayEditForm: {
      type: Boolean,
      required: false,
      default: false,
    },

    deliveryStatusClass: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    video: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    videoThumbnail: {
      type: String,
      required: true,
      default: '',
    },

    hasVideo: {
      type: Boolean,
      required: false,
      default: false,
    },

    canShowLatestRevisionMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showDeliveriesDropdown: false,
      durationList: DurationsConstants.durations,
    }
  },

  computed: {
    ...mapGetters({
      isProjectOnHold: 'project/isProjectOnHold',
      holdDeliveriesLoading: 'project/delivery/holdDeliveriesLoading',
      selectedDeliveryId: 'project/delivery/selectedDeliveryId',
    }),

    updatePayload() {
      return {
        name: this.deliveryDetail.name,
        state_id: this.deliveryDetail.state_id,
        production_date: momentTz
          .unix(this.deliveryDetail.production_date)
          .format('YYYY-MM-DD'),
        due_date: momentTz
          .unix(this.deliveryDetail.due_date)
          .format('YYYY-MM-DD'),
        external_note: this.deliveryDetail.external_note,
        duration: this.deliveryDetail.calendar_time,
        allocated_revisions: this.deliveryDetail.allocated_revisions,
        product_id: this.deliveryDetail.product_id,
        order_job_element_ids: join(
          map(this.deliveryDetail.order_job_elements, 'id')
        ),
      }
    },

    isOnHold() {
      return (
        this.deliveryDetail.status_id ===
        VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD
      )
    },

    deliveryDurationLabel() {
      return this.durationList[this.deliveryDetail.calendar_time]
    },
  },

  methods: {
    ...mapActions({
      unlinkPreProduction: 'project/delivery/unlinkPreProduction',
      linkPreProduction: 'project/delivery/linkPreProduction',
      holdVideoDeliveryJob: 'project/delivery/holdVideoDeliveryJob',
      unholdVideoDeliveryJob: 'project/delivery/unholdVideoDeliveryJob',
      setSelectedEodNoteTypeId: 'project/setSelectedEodNoteTypeId',
      toggleEodNotesModal: 'project/toggleEodNotesModal',
    }),

    toggleDeliveriesDropdown() {
      this.showDeliveriesDropdown = !this.showDeliveriesDropdown
    },

    closeDeliveriesDropdown() {
      this.showDeliveriesDropdown = false
    },

    toggleVideoStar() {
      this.$emit('toggle:video-star')
    },

    toggleVideoPlayer() {
      this.$emit('toggle:video-player', this.deliveryDetail.id)
    },

    canShowHoldLoading(id) {
      return this.holdDeliveriesLoading && this.selectedDeliveryId === id
    },

    addPreProduction(selectedPreProductions) {
      this.linkPreProduction({
        project_id: this.$route.params.projectId,
        name: this.deliveryDetail.name,
        video_job_id: this.deliveryDetail.id,
        includes: ApiCustomIncludes.deliveriesList,
        pre_production_ids: selectedPreProductions,
      })
    },

    removePreProduction(selectedPreProduction) {
      let payload = {
        pre_production_ids: [selectedPreProduction],
      }
      this.unlinkPreProduction({
        project_id: this.$route.params.projectId,
        name: this.deliveryDetail.name,
        order_job_id: this.deliveryDetail.id,
        includes: ApiCustomIncludes.deliveriesList,
        payload: payload,
      })
    },

    handleDeliveryHold() {
      if (this.isProjectOnHold) {
        SweetAlert.fire({
          title: 'Project On Hold',
          html: `<br> Please take the project off hold before making changes on jobs.`,
          showCancelButton: false,
          confirmButtonText: 'Close',
        })
      } else {
        if (this.isOnHold) {
          this.unholdVideoDeliveryJob({
            project_id: this.$route.params.projectId,
            order_job_id: this.deliveryDetail.id,
            name: this.deliveryDetail.name,
            includes: null,
          })
        } else {
          this.holdVideoDeliveryJob({
            project_id: this.$route.params.projectId,
            order_job_id: this.deliveryDetail.id,
            name: this.deliveryDetail.name,
            includes: null,
          })
        }
      }
    },

    handleEodNotesClick() {
      this.setSelectedEodNoteTypeId(this.deliveryDetail.id)
      this.toggleEodNotesModal(true)
    },
  },
}
</script>
