<template>
  <div
    :class="[
      'vms-deliveries__container',
      !isMobileScreen ? 'vms-content__container' : '',
    ]"
  >
    <default-loader
      v-if="deliveriesListLoading"
      class="vd-margin-top-extra-large"
    ></default-loader>

    <div
      v-else
      :class="[
        'vms-deliveries__container--content',
        isMobileScreen ? 'vms-deliveries__container--content__mobile' : '',
      ]"
    >
      <create-video-delivery-job-form
        v-if="videoDeliveryJobNewFormOpen"
      ></create-video-delivery-job-form>
      <empty-deliveries
        v-if="emptyDeliveriesMessage"
        image-src="/images/empty-states/empty-deliveries.svg"
        :description="emptyDeliveriesMessage"
      ></empty-deliveries>

      <div v-else class="vd-padding-left-medium">
        <deliveries-list
          v-for="(delivery, index) in deliveries.data"
          :key="index"
          :delivery-detail="delivery"
        >
          <div
            slot="bulk-delivery"
            :class="[
              'vms-deliveries__content--bulk vms-input__bulk-checkbox vd-padding-right-medium',
              isMobileScreen ? 'vms-deliveries__content--bulk__mobile' : '',
            ]"
          >
            <input
              v-model="selectedDeliveryIds"
              type="checkbox"
              :value="delivery.id"
            />
          </div>
        </deliveries-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultLoader from '@components/loaders/default-loader'
import DeliveriesList from '@views/project/deliveries/project-deliveries-list'
import EmptyDeliveries from '@components/empty-states/project/project-tab-empty-state'
import CreateVideoDeliveryJobForm from '@views/project/components/forms/deliveries/deliveries-form'
import SweetAlert from '@plugins/sweet-alert'
import { map, size, difference } from 'lodash'

export default {
  components: {
    DefaultLoader,
    DeliveriesList,
    EmptyDeliveries,
    CreateVideoDeliveryJobForm,
  },

  provide: {
    formActionType: 'create',
  },

  data() {
    return {
      selectedDeliveryIds: [],
    }
  },

  computed: {
    ...mapGetters({
      deliveriesListLoading: 'project/delivery/deliveriesListLoading',
      deliveries: 'project/delivery/deliveries',
      videoDeliveryJobNewFormOpen:
        'project/delivery/videoDeliveryJobNewFormOpen',
      videoDeliveryJobEditFormOpen:
        'project/delivery/videoDeliveryJobEditFormOpen',
      selectAllDeliveries: 'project/delivery/selectAllDeliveries',
      projectTotalVideos: 'project/projectTotalVideos',
      isMobileScreen: 'common/isMobileScreen',
      hasProjectProductType: 'project/hasProjectProductType',
      projectDetails: 'project/projectDetails',
    }),

    /**
     * Return empty deliveries message.
     */
    emptyDeliveriesMessage() {
      if (this.projectTotalVideos === 0) {
        return 'There are no deliveries added to this project yet.'
      }

      if (this.projectTotalVideos > 0 && this.deliveries.meta.total === 0) {
        return 'There are no videos that match your search.'
      }

      return ''
    },

    /**
     * Available video deliveries array with only the IDs in list.
     */
    videoDeliveryIds() {
      return map(this.deliveries.data, (delivery) => {
        return delivery.id
      })
    },

    /**
     * Check if all videos are selected by checking the size of the remaining video deliveries.
     */
    allDeliveriesSelected() {
      return (
        size(difference(this.videoDeliveryIds, this.selectedDeliveryIds)) === 0
      )
    },
  },

  watch: {
    deliveries() {
      // Reset the selected deliveries array when deliveries data changes.
      // Possible scenarios are pagination per page update, current page update, added or removed deliveries
      this.selectedDeliveryIds = []
    },

    selectAllDeliveries(val) {
      if (val) {
        // Check if all videos are selected then we will update the selected delivery ids array.
        this.selectedDeliveryIds = this.videoDeliveryIds
      } else {
        // Otherwise, we need to reset the selected delivery ids array.
        if (this.allDeliveriesSelected) {
          this.selectedDeliveryIds = []
        }
      }
    },

    selectedDeliveryIds() {
      // We need to keep checking the selected delivery ids array to make sure we only send the correct videos.
      this.setSelectedDeliveryIds(this.selectedDeliveryIds)
    },

    allDeliveriesSelected() {
      // This is to update the deliveries select all store state, so we can update the deliveries sub header checkbox.
      this.setDeliveriesSelectAll(this.allDeliveriesSelected)
    },
  },

  beforeRouteLeave(to, from, next) {
    const vm = this

    if (vm.videoDeliveryJobNewFormOpen || vm.videoDeliveryJobEditFormOpen) {
      SweetAlert.fire({
        title: 'Confirm Navigation',
        html: `<br> You have unsaved changes. <br><br> Are you sure you want to leave this page?`,
        showCancelButton: true,
        confirmButtonText: 'Leave this page',
      }).then((result) => {
        if (result.isConfirmed) {
          vm.showAddNewVideoDeliveryJobForm(false)
          vm.showEditVideoDeliveryJobForm(false)
          next()
        }
      })
    } else {
      next()
    }
  },

  mounted() {
    // We need to reset the selected delivery ids array on rendered to make sure we are only passing the actual selected items if any.
    if (!this.selectAllDeliveries) {
      this.setSelectedDeliveryIds([])
    }

    if (this.hasProjectProductType) {
      this.updatePreProductionTypesList({
        projectId: this.projectDetails.id,
        include: ['product_element_type'],
      })
    }
  },

  methods: {
    ...mapActions({
      showAddNewVideoDeliveryJobForm:
        'project/delivery/showAddNewVideoDeliveryJobForm',
      showEditVideoDeliveryJobForm:
        'project/delivery/showEditVideoDeliveryJobForm',
      setSelectedDeliveryIds: 'project/delivery/setSelectedDeliveryIds',
      setDeliveriesSelectAll: 'project/delivery/setDeliveriesSelectAll',
      updatePreProductionTypesList:
        'project/pre-production/updatePreProductionTypesList',
    }),
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/deliveries';
</style>
